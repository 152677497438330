// 手机号验证
export function isvalidPhone(str) {
    const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
    return reg.test(str)
  }

  // 判断是否含有非法字符
  export function checkSpecificKey(str) {
    var specialKey = "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
    for (var i = 0; i < str.length; i++) {
      if (specialKey.indexOf(str.substr(i, 1)) != -1) {
        return false;
      }
    }
    return true;
  }
  // export function checkSpecificKey(str) {
  //   const reg = /^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]?$/
  //   return reg.test(str)
  // }


  export const areaValidator = (rule, value, callback) => {
    // 有小数的数字校验
    if(Number(value) > 0) {
      value = value + '';
      let str = value.split('.');
      if(Number(value) >= 100000000) {
        return callback(new Error('数量要小于100000000'));
      }
      if(str.length > 1 && str[1].length === 0) {
        return callback(new Error('请输入正确的数'));
      } else if(str.length > 1 && str[1].length > 4) {
        return callback(new Error('最多只能输入4位小数'));
      }
      callback()
    } else {
      return callback(new Error('请输入大于0的数字'))
    }
  }

  export const moneyValidator = (rule, value, callback) => {
    // 有小数的数字校验
    if(Number(value) > 0) {
      value = value + '';
      let str = value.split('.');
      if(Number(value) >= 100000000) {
        return callback(new Error('数量要小于100000000'));
      }
      if(str.length > 1 && str[1].length === 0) {
        return callback(new Error('请输入正确的数'));
      } else if(str.length > 1 && str[1].length > 4) {
        return callback(new Error('最多只能输入4位小数'));
      }
      callback()
    } else {
      return callback(new Error('请输入大于0的数字'))
    }
  }


  // 手机号码校验
export const telephoneValidator = (rule, value, callback) => {
  let reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[0-9])\d{8}$/;
  if (value) {
    if (reg.test(value)) {
      callback()
    } else {
      callback(new Error('请输入正确的手机号'))
    }
    callback()
  } else {
    callback()
  }
}

// 身份证号码校验
export const idCardNoUtil = {
  /*省,直辖市代码表*/
  provinceAndCitys: {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外",
  },

  /*每位加权因子*/
  powers: [
    "7",
    "9",
    "10",
    "5",
    "8",
    "4",
    "2",
    "1",
    "6",
    "3",
    "7",
    "9",
    "10",
    "5",
    "8",
    "4",
    "2",
  ],

  /*第18位校检码*/
  parityBit: ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"],

  /*性别*/
  genders: {
    male: "男",
    female: "女",
  },

  /*校验地址码*/
  checkAddressCode: function (addressCode) {
    var check = /^[1-9]\d{5}$/.test(addressCode);
    if (!check) return false;
    if (idCardNoUtil.provinceAndCitys[parseInt(addressCode.substring(0, 2))]) {
      return true;
    } else {
      return false;
    }
  },

  /*校验日期码*/
  checkBirthDayCode: function (birDayCode) {
    var check =
      /^[1-9]\d{3}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))$/.test(
        birDayCode,
      );
    if (!check) return false;
    var yyyy = parseInt(birDayCode.substring(0, 4), 10);
    var mm = parseInt(birDayCode.substring(4, 6), 10);
    var dd = parseInt(birDayCode.substring(6), 10);
    var xdata = new Date(yyyy, mm - 1, dd);
    if (xdata > new Date()) {
      return false; //生日不能大于当前日期
    } else if (
      (xdata.getFullYear() == yyyy) && (xdata.getMonth() == mm - 1) &&
      (xdata.getDate() == dd)
    ) {
      return true;
    } else {
      return false;
    }
  },

  /*计算校检码*/
  getParityBit: function (idCardNo) {
    var id17 = idCardNo.substring(0, 17);
    /*加权 */
    var power = 0;
    for (var i = 0; i < 17; i++) {
      power += parseInt(id17.charAt(i), 10) * parseInt(idCardNoUtil.powers[i]);
    }
    /*取模*/
    var mod = power % 11;
    return idCardNoUtil.parityBit[mod];
  },

  /*验证校检码*/
  checkParityBit: function (idCardNo) {
    var parityBit = idCardNo.charAt(17).toUpperCase();
    if (idCardNoUtil.getParityBit(idCardNo) == parityBit) {
      return true;
    } else {
      return false;
    }
  },

  /*校验15位或18位的身份证号码*/
  checkIdCardNo: function (idCardNo) {
    //15位和18位身份证号码的基本校验
    var check = /^\d{15}|(\d{17}(\d|x|X))$/.test(idCardNo);
    if (!check) return false;
    //判断长度为15位或18位
    if (idCardNo.length == 15) {
      return idCardNoUtil.check15IdCardNo(idCardNo);
    } else if (idCardNo.length == 18) {
      return idCardNoUtil.check18IdCardNo(idCardNo);
    } else {
      return false;
    }
  },

  //校验15位的身份证号码
  check15IdCardNo: function (idCardNo) {
    //15位身份证号码的基本校验
    var check =
      /^[1-9]\d{7}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))\d{3}$/
        .test(idCardNo);
    if (!check) return false;
    //校验地址码
    var addressCode = idCardNo.substring(0, 6);
    check = idCardNoUtil.checkAddressCode(addressCode);
    if (!check) return false;
    var birDayCode = "19" + idCardNo.substring(6, 12);
    //校验日期码
    return idCardNoUtil.checkBirthDayCode(birDayCode);
  },

  //校验18位的身份证号码
  check18IdCardNo: function (idCardNo) {
    //18位身份证号码的基本格式校验
    var check =
      /^[1-9]\d{5}[1-9]\d{3}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))\d{3}(\d|x|X)$/
        .test(idCardNo);
    if (!check) return false;
    //校验地址码
    var addressCode = idCardNo.substring(0, 6);
    check = idCardNoUtil.checkAddressCode(addressCode);
    if (!check) return false;
    //校验日期码
    var birDayCode = idCardNo.substring(6, 14);
    check = idCardNoUtil.checkBirthDayCode(birDayCode);
    if (!check) return false;
    //验证校检码
    return idCardNoUtil.checkParityBit(idCardNo);
  },

  formateDateCN: function (day) {
    var yyyy = day.substring(0, 4);
    var mm = day.substring(4, 6);
    var dd = day.substring(6);
    return yyyy + "-" + mm + "-" + dd;
  },

  //获取信息
  getIdCardInfo: function (idCardNo) {
    var idCardInfo = {
      gender: "", //性别
      birthday: "", // 出生日期(yyyy-mm-dd)
    };
    if (idCardNo.length == 15) {
      var aday = "19" + idCardNo.substring(6, 12);
      idCardInfo.birthday = idCardNoUtil.formateDateCN(aday);
      if (parseInt(idCardNo.charAt(14)) % 2 == 0) {
        idCardInfo.gender = idCardNoUtil.genders.female;
      } else {
        idCardInfo.gender = idCardNoUtil.genders.male;
      }
    } else if (idCardNo.length == 18) {
      var aday = idCardNo.substring(6, 14);
      idCardInfo.birthday = idCardNoUtil.formateDateCN(aday);
      if (parseInt(idCardNo.charAt(16)) % 2 == 0) {
        idCardInfo.gender = idCardNoUtil.genders.female;
      } else {
        idCardInfo.gender = idCardNoUtil.genders.male;
      }
    }
    return idCardInfo;
  },

  /*18位转15位*/
  getId15: function (idCardNo) {
    if (idCardNo.length == 15) {
      return idCardNo;
    } else if (idCardNo.length == 18) {
      return idCardNo.substring(0, 6) + idCardNo.substring(8, 17);
    } else {
      return null;
    }
  },

  /*15位转18位*/
  getId18: function (idCardNo) {
    if (idCardNo.length == 15) {
      var id17 = idCardNo.substring(0, 6) + "19" + idCardNo.substring(6);
      var parityBit = idCardNoUtil.getParityBit(id17);
      return id17 + parityBit;
    } else if (idCardNo.length == 18) {
      return idCardNo;
    } else {
      return null;
    }
  },
};

// 银行卡号
export const luhnCheck = (rule, bankno, callback) => {
  var lastNum = bankno.substr(bankno.length - 1, 1); //取出最后一位（与luhn进行比较）
  var first15Num = bankno.substr(0, bankno.length - 1); //前15或18位
  var newArr = new Array();
  for (var i = first15Num.length - 1; i > -1; i--) { //前15或18位倒序存进数组
      newArr.push(first15Num.substr(i, 1));
  }
  var arrJiShu = new Array(); //奇数位*2的积 <9
  var arrJiShu2 = new Array(); //奇数位*2的积 >9
  var arrOuShu = new Array(); //偶数位数组
  for (var j = 0; j < newArr.length; j++) {
      if ((j + 1) % 2 == 1) { //奇数位
          if (parseInt(newArr[j]) * 2 < 9) arrJiShu.push(parseInt(newArr[j]) * 2);
          else arrJiShu2.push(parseInt(newArr[j]) * 2);
      } else //偶数位
      arrOuShu.push(newArr[j]);
  }
  var jishu_child1 = new Array(); //奇数位*2 >9 的分割之后的数组个位数
  var jishu_child2 = new Array(); //奇数位*2 >9 的分割之后的数组十位数
  for (var h = 0; h < arrJiShu2.length; h++) {
      jishu_child1.push(parseInt(arrJiShu2[h]) % 10);
      jishu_child2.push(parseInt(arrJiShu2[h]) / 10);
  }
  var sumJiShu = 0; //奇数位*2 < 9 的数组之和
  var sumOuShu = 0; //偶数位数组之和
  var sumJiShuChild1 = 0; //奇数位*2 >9 的分割之后的数组个位数之和
  var sumJiShuChild2 = 0; //奇数位*2 >9 的分割之后的数组十位数之和
  var sumTotal = 0;
  for (var m = 0; m < arrJiShu.length; m++) {
      sumJiShu = sumJiShu + parseInt(arrJiShu[m]);
  }
  for (var n = 0; n < arrOuShu.length; n++) {
      sumOuShu = sumOuShu + parseInt(arrOuShu[n]);
  }
  for (var p = 0; p < jishu_child1.length; p++) {
      sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p]);
      sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p]);
  }
  //计算总和
  sumTotal = parseInt(sumJiShu) + parseInt(sumOuShu) + parseInt(sumJiShuChild1) + parseInt(sumJiShuChild2);
  //计算luhn值
  var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10;
  var luhn = 10 - k;
  if (lastNum == luhn) {
      // $("#banknoInfo").html("luhn验证通过");
      // return true;
      callback();
  } else {
      // $("#banknoInfo").html("银行卡号必须符合luhn校验");
      // return false;
      callback(new Error('请输入正确的银行卡号！'))
  }
}


// 纳税人识别号
export const checkTaxpayerId = (rule, taxpayerId, callback) => {
  if(taxpayerId!="" && taxpayerId.length===15){
      var addressCode = taxpayerId.substring(0,6);
      // 校验地址码
      var check = checkAddressCode(addressCode);
      if(!check){
          // return false;
          callback(new Error('请输入正确的税号！'))
      }
      // 校验组织机构代码
      var orgCode = taxpayerId.substring(6,9);
      check = isValidOrgCode(orgCode);
      if(!check){
          // return false;
          callback(new Error('请输入正确的税号！'))
      }
      // return true;
      callback();
  }else{
      // return false;
      callback(new Error('纳税号长度为15位！'))
  }

  function isValidOrgCode(value){
    if(value!=""){
        var part1=value.substring(0,8);
        var part2=value.substring(value.length-1,1);
        var ws = [3, 7, 9, 10, 5, 8, 4, 2];
        var str = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var reg = /^([0-9A-Z]){8}$/;
        if (!reg.test(part1))
        {
            return true
        }
        var sum = 0;
        for (var i = 0; i< 8; i++)
        {
            sum += str.indexOf(part1.charAt(i)) * ws[i];
        }
        var C9 = 11 - (sum % 11);
        var YC9=part2+'';
        if (C9 == 11) {
            C9 = '0';
        } else if (C9 == 10) {
            C9 = 'X' ;
        } else {
            C9 = C9+'';
        }
        return YC9!=C9;
    }
  }

  function checkAddressCode(addressCode){
    var provinceAndCitys={11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江",
        31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北",43:"湖南",44:"广东",
        45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏",61:"陕西",62:"甘肃",63:"青海",64:"宁夏",
        65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外"};
    var check = /^[1-9]\d{5}$/.test(addressCode);
    if(!check) return false;
    if(provinceAndCitys[parseInt(addressCode.substring(0,2))]){
        return true;
    }else{
        return false;
    }
  }
}


// 纳税人识别号18位校验码
export const checkCode = (rule, value, callback) => {
  var code = value;
  if(code.length != 18){
      // alert("社会信用代码长度错误！");
      // return false;
      callback(new Error('税号长度有误！'))
  }
  var reg = /^([0-9ABCDEFGHJKLMNPQRTUWXY]{2})([0-9]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-9Y])$/;
  if(!reg.test(code)){
      // alert("社会信用代码校验错误！");
      // return false;
      callback(new Error('请输入正确的税号！'))
  }
  var str = '0123456789ABCDEFGHJKLMNPQRTUWXY';
  var ws =[1,3,9,27,19,26,16,17,20,29,25,13,8,24,10,30,28];
  var codes  = new Array();
  codes[0] = code.substr(0,code.length-1);
  codes[1] = code.substr(code.length-1,code.length);
  var sum = 0;
  for(var i=0;i<17;i++){
      sum += str.indexOf(codes[0].charAt(i)) * ws[i];
  }
  var c18 = 31 - (sum % 31);
  if(c18 == 31){
      // alert("第18位 == 31");
      c18 = 'Y';
  }else if(c18 == 30){
      // alert("第18位 == 30");
      c18 = '0';
  }
  if(c18 != codes[1]){
      // alert("社会信用代码有误！"+c18);
      // return false;
      callback(new Error('请输入正确的税号！'))
  }
  callback();
}
